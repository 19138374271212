/*
@font-face {
    font-family: 'roboto';
    src: local('roboto'), url('./App/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'roboto-bold';
    src: local('roboto-bold'), url('./App/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'roboto-light';
    src: local('roboto-light'), url('./App/assets/fonts/Roboto-Light.ttf') format('truetype');
}
*/


body {
    min-height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', '|Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
